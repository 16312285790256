/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { LinkButton } from "gatsby-interface"

import Container from "../../components/container"
import Header from "../../components/header"
import Title from "../../components/hero/title"
import Layout from "../../layouts"
import PageMetadata from "../../components/page-metadata"
import LegendTable from "../../components/features/legend-table"
import SimpleEvaluationTable from "../../components/features/simple-evaluation-table"
import PageWithSidebar from "../../components/page-with-sidebar"
import FooterLinks from "../../components/shared/footer-links.js"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"

const FeaturesHeader = () => (
  <section>
    <p>
      There are many ways to build a website. If you’re considering Gatsby, you
      may also be looking at some alternatives:
    </p>
    <ul>
      <li>
        <strong>JAMstack frameworks</strong> such as
        {` `}
        <a
          href="https://jekyllrb.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Jekyll
        </a>
        {`, `}
        <a href="https://nextjs.org/" target="_blank" rel="noopener noreferrer">
          Next.js
        </a>
        , and
        {` `}
        <a href="https://nuxtjs.org/" target="_blank" rel="noopener noreferrer">
          Nuxt.js
        </a>
        {` `}
        let you put text or markdown in a specific directory such as{` `}
        <code>pages/</code> in a version-controlled codebase. They then build a
        specific kind of site, usually a blog, as HTML files from the content
        you’ve added. These files can be cached and served from a CDN.
        <br />
        <p sx={{ mt: 3 }}>Coming from the JAMstack world?</p>
        <LinkButton to="/features/jamstack/" variant="SECONDARY" size="M">
          Compare Gatsby vs JAMstack
        </LinkButton>
      </li>
      <li>
        <strong>Traditional content management systems</strong> (CMSs) like
        {` `}
        <a
          href="https://wordpress.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          WordPress
        </a>
        {` `}
        and
        {` `}
        <a href="https://drupal.org/" target="_blank" rel="noopener noreferrer">
          Drupal
        </a>
        {` `}
        give you an online text editor to create content. You customize the look
        and feel by choosing themes and plugins or by writing custom PHP or
        JavaScript code. Content is saved in a database, which is retrieved and
        sent to users when they visit the website. Depending on your
        requirements you can self-host your website or use an official hosting
        provider.
        <br />
        <p sx={{ mt: 3 }}>Coming from the CMS world?</p>
        <LinkButton to="/features/cms/" variant="SECONDARY" size="M">
          Compare Gatsby vs CMS
        </LinkButton>
      </li>
    </ul>
    <p>
      The chart below details Gatsby’s capabilities in comparison with a
      representative from each category. Click on any row to see a more detailed
      explanation on that feature and our rating for each system.
    </p>
    <LegendTable />
  </section>
)

const FeaturesPage = ({ data: { allGatsbyFeaturesSpecsCsv }, location }) => {
  return (
    <Layout>
      <Header isFullWidth="true" isFixed />
      <PageMetadata
        title="Features"
        description="Learn how specific features like performance and support for modern technologies make Gatsby worth using."
      />
      <PageWithSidebar location={location}>
        <main
          sx={t => ({
            pb: 0,
            [t.mediaQueries.desktop]: {
              pt: 10,
            },
          })}
        >
          <SkipNavTarget />
          <Container css={{ position: `static` }}>
            <Title>Features Overview</Title>
            <FeaturesHeader />
            <SimpleEvaluationTable
              title="Feature Comparison"
              headers={[
                { display: `Category`, nodeFieldProperty: `Category` },
                { display: `Gatsby`, nodeFieldProperty: `Gatsby` },
                {
                  display: `JAMstack frameworks`,
                  nodeFieldProperty: `Jamstack`,
                },
                { display: `Traditional CMS`, nodeFieldProperty: `Cms` },
              ]}
              data={allGatsbyFeaturesSpecsCsv.nodes}
            />
            <FooterLinks />
          </Container>
        </main>
      </PageWithSidebar>
    </Layout>
  )
}

export default FeaturesPage

export const pageQuery = graphql`
  query FeaturePageQuery {
    allGatsbyFeaturesSpecsCsv {
      nodes {
        Category
        Gatsby
        Jamstack
        Cms
        Description
      }
    }
  }
`
